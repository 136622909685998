<template>
  <v-app>
    <v-main>
      <v-navigation-drawer   
        expand-on-hover
        rail>

        <v-list>
          <v-list-item>
            <template v-slot:prepend>
              <v-avatar>
                <v-img src="./assets/profile.jpg"/>
              </v-avatar>
            </template>
            <v-list-item-title>DataWyrd</v-list-item-title>
            <v-list-item-subtitle>
              <a href="https://github.com/DataWyrd" target="_blank">github.com/DataWyrd</a>
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>


        <v-divider></v-divider>


        <v-list dense nav>
          <v-list-item v-for="item in items" :key="item.title" :prepend-icon="item.icon" @click="navigate(item)">
            {{ item.title }}
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <div>
            <v-list-item prepend-icon="mdi-theme-light-dark" @click="toggleTheme">
            Toggle Light Mode
          </v-list-item>
          </div>
        </template>

      </v-navigation-drawer>

      <router-view></router-view>

      <v-bottom-navigation v-model="value" color="brown" grow>
        <v-btn v-for="item in items" :key="item.title" @click="navigate(item)">
          <v-icon>{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-btn>
      </v-bottom-navigation>


    </v-main>
  </v-app>
</template>

<script setup>
import { useTheme } from 'vuetify'

const theme = useTheme()

function toggleTheme () {
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
}
</script>

<script>

export default {
  name: 'App',

  components: {

  },
  data: () => ({
    value: null,
    items: [
      { title: 'Dig Timer', icon: 'mdi-clock', route: '/dig-timer' },
      { title: 'Digger Dashboard', icon: 'mdi-view-dashboard-outline', route: '/dashboard' },
      { title: 'Dig Donations', icon: 'mdi-ethereum', route: '/dig-donations' }
    ]
  }),

  methods: {
    navigate(item) {
      this.$router.push(item.route);
    },
  }
}
</script>
