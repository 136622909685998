import { createRouter, createWebHistory } from 'vue-router';
import DigTimeView from './components/DigTimeView.vue';
import DashboardView from './components/DashboardView.vue';
import DigDonationsView from './components/DigDonationsView.vue';

const routes = [
  { path: '/', redirect: '/dig-timer' }, 
  { path: '/dig-timer', component: DigTimeView },
  { path: '/dashboard', component: DashboardView },
  { path: '/dig-donations', component: DigDonationsView },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;