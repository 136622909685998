<template>
    <v-card class="mx-auto my-4" max-width="380" outlined>
      <v-img :src="nft.image" height="200px" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"></v-img>
      
      <v-card-title>{{ nft.name }}</v-card-title>
  
      <v-card-subtitle>{{ nft.collection }}</v-card-subtitle>
  
        <v-card-text>
            <div>{{ nft.description }}</div>
                <v-chip-group class="d-flex justify-center">
                    <v-chip
                        v-for="stat in nft.stats"
                        :key="stat.label"
                        small
                        pill
                    >
                    <v-icon left small>{{ stat.icon }}</v-icon>
                {{ stat.label }}: {{ stat.value }}
                </v-chip>
            </v-chip-group>
        </v-card-text>
    
      <v-card-actions>
        <v-btn text>View</v-btn>
        <v-btn text>Buy</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  export default {
    props: {
      nft: {
        type: Object,
        required: true,
      },
    },
  };
  </script>
  