<template>
    <div ref="canvasContainer" class="visualizer-container" @click="toggleAnimation"></div>
  </template>
  
  <script>
  import * as THREE from 'three';
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
  
  export default {
    name: 'BoxVisualizer',
    props: {
      boxWidth: {
        type: Number,
        default: 1,
      },
      boxHeight: {
        type: Number,
        default: 1,
      },
      boxDepth: {
        type: Number,
        default: 1,
      },
    },
    data() {
      return {
        isAnimating: true,
      };
    },
    mounted() {
      this.initThreeJS();
      this.updateCameraPosition();
    },
    beforeUnmount() {
      this.controls.dispose();
      window.removeEventListener('resize', this.onWindowResize);
      // Additional cleanup as necessary
    },
    methods: {
      initThreeJS() {
        const width = this.$refs.canvasContainer.offsetWidth;
        const height = this.$refs.canvasContainer.offsetHeight;
  
        // Scene
        this.scene = new THREE.Scene();
  
        // Camera
        this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        this.camera.position.z = 5;
  
        // Renderer
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setSize(width, height);
        this.$refs.canvasContainer.appendChild(this.renderer.domElement);
  
        // Box
        this.updateBoxDimensions();
  
        // Controls
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.addEventListener('change', this.renderScene); // Re-render on control change
  
        // Animation Loop
        this.animate();
  
        // Responsive Resize
        window.addEventListener('resize', this.onWindowResize);
      },
      animate() {
        if (this.isAnimating) {
          requestAnimationFrame(this.animate);
          this.controls.update();
        }
        this.renderScene();
      },
      renderScene() {
        this.renderer.render(this.scene, this.camera);
      },
      updateBoxDimensions() {
        if (this.box) {
          this.scene.remove(this.box);
          this.geometry.dispose();
        }
        this.geometry = new THREE.BoxGeometry(this.boxWidth, this.boxHeight, this.boxDepth);
        const material = new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true });
        this.box = new THREE.Mesh(this.geometry, material);
        this.scene.add(this.box);
      },
      toggleAnimation() {
        this.isAnimating = !this.isAnimating;
        if (this.isAnimating) this.animate(); // Resume the animation
      },
      onWindowResize() {
        const width = this.$refs.canvasContainer.offsetWidth;
        const height = this.$refs.canvasContainer.offsetHeight;
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(width, height);
        this.renderScene();
      },
      updateCameraPosition() {
        // Calculate the "diagonal" size of the box for a simple approximation of fitting the box in view
        const maxSize = Math.sqrt(this.boxWidth ** 2 + this.boxHeight ** 2 + this.boxDepth ** 2);

        // Calculate a suitable distance. The factor '1.5' is arbitrary and may need adjustment.
        const distance = maxSize * 1.5;

        // Adjust the camera position. Assuming the box is centered at the origin.
        this.camera.position.set(0, 0, distance);
        
        // Ensure the camera is pointing at the center of the box
        this.camera.lookAt(this.scene.position);

        // Adjust the camera's near and far clipping planes to avoid clipping issues
        this.camera.near = distance / 10;
        this.camera.far = distance * 10;

        // Update the camera's projection matrix to apply near and far changes
        this.camera.updateProjectionMatrix();

        // Re-render the scene with the updated camera position
        this.renderScene();
    },
    
    },
    watch: {
      boxWidth: 'updateBoxDimensions',
      boxHeight: 'updateBoxDimensions',
      boxDepth: 'updateBoxDimensions',
    },
  };
  </script>
  
  <style>
  .visualizer-container {
    width: 100%;
    height: 400px; /* Adjust based on your needs */
    cursor: pointer; /* Indicates interactivity */
  }
  </style>
  