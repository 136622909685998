<template>
    <v-banner :color="color" :lines="lines">
      <template v-slot:prepend>
        <v-avatar :color="color" :icon="icon"></v-avatar>
      </template>
      <v-banner-text>{{ text }}</v-banner-text>
      <v-banner-actions>
        <v-btn v-for="(action, index) in actions" :key="index">{{ action }}</v-btn>
      </v-banner-actions>
    </v-banner>
  </template>
  
  <script>
  export default {
    props: {
      color: String,
      lines: Number,
      icon: String,
      text: String,
      actions: Array
    }
  };
  </script>