<template>
    <div class="chart-container">
      <canvas ref="chartCanvas"></canvas>
    </div>
  </template>
  
  <script>
  import { Chart, registerables } from 'chart.js';
  Chart.register(...registerables);
  
  export default {
    props: {
      chartData: {
        type: Object,
        required: true,
      },
      chartType: {
        type: String,
        default: 'bar',
      },
      chartOptions: Object
    },

    mounted() {
        this.renderChart();
    },
    methods: {
        renderChart() {
            const ctx = this.$refs.chartCanvas.getContext('2d');
            new Chart(ctx, {
            type: this.chartType,
            data: this.chartData,
            options: this.chartOptions
            });
        },
        },
    };
  </script>
  
  <style>
  .chart-container {
    position: relative;
    height: 100%; /* Adjust height as needed */
    width: 100%;
  }
  </style>
  