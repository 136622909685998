<template>
  <v-container>
    <v-row>
      <!-- Dashboard Header -->
      <v-col cols="12" class="mb-3 d-flex justify-center">
        <div>
          <v-row align="center">
            <h1 class="ml-4 mt-6 mb-6 text-h4 font-weight-semi-bold">
              Dig Time Calculator
            </h1>
          </v-row>
        </div>
      </v-col>

      <v-col
        cols="12"
      >
        <v-row>
          <!-- Dimensions -->
          <v-col>
            <v-card flat>
              <v-card-title class="d-flex align-center pe-2">
                <v-icon icon="mdi-axis-arrow"></v-icon> &nbsp;
                 Excavation Area

                <v-spacer></v-spacer>

                <v-btn :disabled="!isDimensionsPartiallyValid"
                  @click="dimensionsDialog = true" 
                  prepend-icon="mdi-calculator" 
                  size="small"
                  class="bg-primary"
                 >
                  View Area
                </v-btn>
              </v-card-title>

              <v-sheet 
                class="pa-3"
                rounded
              >          
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="this.diggingArea.length"
                      label="Length"
                      prepend-inner-icon="mdi-axis-y-arrow"
                      variant="outlined"
                      type="number"
                      :rules="[value => !!value || 'Length is required', value => /^\d*\.?\d+$/.test(value) || 'Enter a valid number']"
                    ></v-text-field>
                  </v-col> 
                  <v-col>
                    <v-text-field
                      v-model="this.diggingArea.width"
                      label="Width"
                      prepend-inner-icon="mdi-axis-x-arrow"
                      variant="outlined"
                      type="number"
                      :rules="[value => !!value || 'Width is required', value => /^\d*\.?\d+$/.test(value) || 'Enter a valid number']"
                    ></v-text-field>
                  </v-col>

                  <v-col>
                    <v-text-field
                      v-model="this.diggingArea.depth"
                      label="Depth"
                      prepend-inner-icon="mdi-axis-z-arrow"
                      variant="outlined"
                      type="number"
                      :rules="[value => !!value || 'Depth is required', value => /^\d*\.?\d+$/.test(value) || 'Enter a valid number']"
                    ></v-text-field>
                  </v-col>

                </v-row>
                <v-row>
                  <v-col>

                    <v-combobox
                      variant="outlined"
                      v-model="this.diggingArea.unit"
                      :items="areaUnits"
                      label="Unit of Measure"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          :key="JSON.stringify(data.item)"
                          v-bind="data.attrs"
                          :disabled="data.disabled"
                          :model-value="data.selected"
                          class="bg-primary"
                          @click:close="data.parent.selectItem(data.item)"
                        >
                          {{ data.item.title }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
              </v-sheet>
      
            </v-card>
          </v-col>

          <!-- Add People -->
          <v-col>
            <v-card flat>
              <v-card-title class="d-flex align-center pe-2">
                <v-icon icon="mdi-account-plus"></v-icon> &nbsp;
                Add Laborer

                <v-spacer></v-spacer>

                <v-btn 
                  @click="laborerInfoDialog = true" 
                  size="small"
                  density="comfortable"
                  class="bg-primary mr-2" 
                  icon="mdi-information-outline " 
                >
                
                </v-btn>

                <v-btn 
                  @click="addLaborer()" 
                 prepend-icon="mdi-plus" 
                 size="small"
                 class="bg-primary" 
                 :disabled="!isNewLaborerValid"
                 
                >
                  Add Laborer
                </v-btn>
              </v-card-title>

                  <v-sheet        
                    class="pa-3"
                    rounded
                  >          
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="this.newLaborer.name"
                          label="Name"
                          prepend-inner-icon="mdi-account"
                          variant="outlined"
                          :rules="[value => !!value || 'Name is required', value => /^[a-zA-Z\s]*$/.test(value) || 'Enter a valid name']"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="this.newLaborer.digRate"
                          label="Dig Rate"
                          prepend-inner-icon="mdi-shovel"
                          variant="outlined"
                          type="number"
                          :rules="[value => !!value || 'Rate is required', value => /^\d*\.?\d+$/.test(value) || 'Enter a valid number']"
                        >
                          <template v-slot:append>
                            <v-chip
                              v-if="this.diggingArea.unit"
                            > {{ this.diggingArea.unit }}</v-chip>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col>
                        <v-combobox
                          variant="outlined"
                          v-model="this.newLaborer.digRateUnit"
                          :items="timeUnits"
                          label="Per Unit of Time"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :disabled="data.disabled"
                              :model-value="data.selected"
                              class="bg-primary"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item.title }}
                            </v-chip>
                          </template>
                        </v-combobox>

                      </v-col>

                  </v-row>
                </v-sheet>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- People List -->
      <v-col
        cols="12"
      >
      <v-card flat>
        <v-card-title class="d-flex align-center pe-2">
          <v-icon icon="mdi-account-group"></v-icon> &nbsp;
          Laborer List

          <v-spacer></v-spacer>

          <v-btn 
            @click="exportDataDialog = true" 
            prepend-icon="mdi-export" 
            size="small"
            class="bg-primary"
          >
            Export Data
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-list lines="two" max-height="250">
          <v-list-subheader>People</v-list-subheader>

          <v-list-item
            v-for="(laborer, index) in laborerList"
            :key="laborer.name"
            :subtitle="laborer.digRate + ' ' + this.diggingArea.unit  + '/' + laborer.digRateUnit"
            :title="laborer.name"
          >
            <template v-slot:prepend>
              <v-avatar color="primary">
                <v-icon color="white">mdi-account-hard-hat</v-icon>
              </v-avatar>
            </template>

            <template v-slot:append>
              <v-btn
                @click="viewLaborerInformation()"
                color="primary"
                icon="mdi-information"
                variant="text"
              ></v-btn>

              <v-btn
                @click=" removeLaborer(index)"
                color="black"
                icon="mdi-delete"
                variant="text"
              ></v-btn>
            </template>
          </v-list-item>


        </v-list>

        <div  v-if="!this.isLaborerListValid" style="text-align: center;" class="mt-4 mb-8">
          <v-icon size="56" style="margin-bottom: 10px;">mdi-account-remove-outline</v-icon>
          <p style="font-size: 16px; color: #888;">No laborers added yet. Fill the form above to add laborers</p>
        </div>

      </v-card>

      </v-col>

      <!-- Results -->
      <v-col
        cols="12"
      >
      <v-card flat class="px-2">
        <v-card-title class="d-flex align-center pe-2">
          <v-icon icon="mdi-card-bulleted-settings-outline"></v-icon> &nbsp;
          Excavation Results

          <v-spacer></v-spacer>
        </v-card-title>

        <v-divider></v-divider>


        <v-sheet
          class=" my-12 pa-4 text-center mx-auto"
          elevation="12"
          max-width="600"
          rounded="lg"
          width="100%"
        >
          <v-icon v-if="!this.isLaborerListValid || !this.isDimensionsValid"
            class="mb-5"
            color="error"
            icon="mdi-alert-circle"
            size="112"
          ></v-icon>

          <v-icon v-else
            class="mb-5"
            color="primary"
            icon="mdi-check-circle"
            size="112"
          ></v-icon>

          <h2 v-if="!this.isDimensionsValid && this.isLaborerListValid" class="text-h5 mb-6">The Excavation Area Needs Values</h2>

          <h2 v-if="!this.isLaborerListValid && this.isDimensionsValid" class="text-h5 mb-6">Atleast One Laborer is Required</h2>

          <h2 v-if="!this.isLaborerListValid && !this.isDimensionsValid" class="text-h5 mb-6">Excavation Area and Laborers are Required</h2>

          <h2 v-if="this.isLaborerListValid && this.isDimensionsValid" class="text-h5 mb-6">This Job Will Take {{ this.totalDiggingTime }} to Complete</h2>

          <p class="mb-4 text-medium-emphasis text-body-2">
            To explore more features and functionalities, <a class="text-decoration-none text-info" href="#"> navigate </a> to the respective sections.

            <br>

            Otherwise, enjoy using the application!
          </p>

          <v-divider class="mb-4" v-if="this.isLaborerListValid || this.isDimensionsValid"></v-divider>

          <div class="text-end">
            <v-btn
              class="mr-2 text-none"
              color="error"
              variant="flat"
              v-if="this.isLaborerListValid || this.isDimensionsValid"
              @click="resetDataDialog = true" 
              rounded
            >
              Reset All
            </v-btn>

            <v-btn
              class="text-none"
              color="primary"
              variant="flat"
              rounded
              v-if="this.isLaborerListValid && this.isDimensionsValid"
            >
              View Dashboard
            </v-btn>

          </div>
        </v-sheet>

      </v-card>

      </v-col>

    </v-row>


    <div class="text-center pa-4">
      <v-dialog
        v-model="dimensionsDialog"
        max-width="800px"
      >
        <v-sheet class="pa-2">
          <dimensional-box v-if="dimensionsDialog" :boxWidth="diggingArea.width" :boxHeight="diggingArea.length" :boxDepth="diggingArea.depth"></dimensional-box>
        </v-sheet>
    
      </v-dialog>
    </div>

    <div class="text-center pa-4">
      <v-dialog
        v-model="exportDataDialog"
        width="400px"
      >
        <v-card>
          <v-card-title>Export Data</v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select v-model="selectedExportType" :items="exportTypes" label="Export Type"></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-chip v-if="selectedExportType" close @click:close="selectedExportType = null">{{ selectedExportType }}</v-chip>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="exportData">Export</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center pa-4">
      <v-dialog
        v-model="laborerInfoDialog"
        width="auto"
      >
        <v-card class="pa-3">
          <v-card-title>Helpful Information for Measuring Shoveling Capacity</v-card-title>
          
    
          <v-list :items="diggingTips" lines="three" item-props>
            <template v-slot:subtitle="{ subtitle }">
              <div v-html="subtitle"></div>
            </template>
          </v-list>
      
        </v-card>
  
      </v-dialog>
    </div>

    <div class="text-center pa-4">
      <v-dialog
        v-model="resetDataDialog"
        width="auto"
      >
        <v-card
          max-width="550"
          text="All data will be lost, are you sure?"
          title="Reset Current Form"
        >
          <template v-slot:actions>
            <v-btn
              class="ms-auto"
              color="primary"
              text="Confirm"
              @click="resetAllFormData(); resetDataDialog = false"
            ></v-btn>
          </template>
        </v-card>
      </v-dialog>
    </div>

  </v-container>
</template>

<script>
import { DiggingRate } from '../math/diggingRate';
import DimensionalBox from '../components/DimensionalBoxComponent.vue';

class Digger {
  constructor(name, digRate, digRateUnit) {
    this.name = name;
    this.digRate = digRate;
    this.digRateUnit = digRateUnit;
  }

  getDiggerWorkTime(diggingArea, digRate) {
    const diggingRate = new DiggingRate();
    const time = diggingRate.GetDiggingHours(diggingArea, digRate).toFixed(2);
    return time;
  }
}


export default {
  name: 'DigTimeView',
  components: {
    DimensionalBox,
  },

  data: () => ({
    //misc
    totalDiggingTime: '',
    redrawKey: 0,

    //form elements
    diggingArea: { 
      width: null, 
      length: null,
      depth: null,
      unit: 'Feet³'
    },

    newLaborer: {
      name: '',
      digRate: 0,
      digRateUnit: '1 Minute'
    },

    //dialogs
    dimensionsDialog: false,
    exportDataDialog: false,
    resetDataDialog: false,
    laborerInfoDialog: false,
    
    areaUnits: [
      'Feet³',
      'Yards³',
      'Meters³',
      'Miles³',
      'Kilometers³',
    ],

    timeUnits: [
      '10 Seconds',
      '30 Seconds',
      '1 Minute',
      '10 Minutes',
      '30 Minutes',
    ],

    exportTypes: ['CSV', 'Excel', 'PDF'],
    selectedExportType: null,

    diggingTips: [
      {
        prependIcon: 'mdi-ruler',
        title: 'Cubic Measurement',
        subtitle: 'The volume of material shoveled in cubic units (e.g., cubic feet or cubic meters).',
      },
      { type: 'divider', inset: true },
      {
        prependIcon: 'mdi-timer',
        title: 'Rate of Shoveling',
        subtitle: 'The speed at which material is shoveled, usually measured in cubic units per hour.',
        iconClass: 'icon-big icon-border'
      },
      { type: 'divider', inset: true },
      {
        prependIcon: 'mdi-shovel',
        title: 'Average Shovel Size',
        subtitle: 'The typical dimensions of the shovel blade, impacting the volume of material lifted per scoop.',
      },
      { type: 'divider', inset: true },
      {
        prependIcon: 'mdi-human-greeting',
        title: 'Physical Endurance',
        subtitle: "The individual's stamina and ability to sustain shoveling over time.",
      },
      { type: 'divider', inset: true },
      {
        prependIcon: 'mdi-wrench',
        title: 'Technique',
        subtitle: 'The efficiency and ergonomics of the shoveling method used.',
      },
    ],

    laborerList: [],
  }),

  computed: {
    isLaborerListValid() {
      return this.laborerList.length > 0;
    },

    isDimensionsValid() {
      const { length, width, depth, unit } = this.diggingArea;
      return length > 0 && width > 0 && depth > 0 && unit !== '';
    },

    isDimensionsPartiallyValid() {
      const { length, width, depth } = this.diggingArea;
      return length > 0 || width > 0 || depth > 0;
    },

    isNewLaborerValid() {
      const { name, digRate, digRateUnit } = this.newLaborer;
      return name !== ''  && digRate > 0 && digRateUnit !== '';
    }
  },

  watch: {
    diggingArea: {
      handler() {
        this.calculateTimeToDigHole();
      },
      deep: true
    },
    laborerList: {
      handler() {
        this.calculateTimeToDigHole();
      },
      deep: true
    }
  },

  methods: {
    addLaborer() {
      const { name, digRate, digRateUnit } = this.newLaborer;
      this.laborerList.push(new Digger(name, digRate, digRateUnit));
      this.resetAddLaborerInputs();
    },

    resetAddLaborerInputs() {
      this.newLaborer = {};
      this.setDefaultLaborerUnit();
    },

    resetDimensionInputs() {
      this.diggingArea = {};
      this.setDefaultdiggingAreaUnit();
    },

    setDefaultdiggingAreaUnit() {
      this.diggingArea.unit = "Feet³"
    },

    setDefaultLaborerUnit() {
      this.newLaborer.digRateUnit = "1 Minute"
    },

    removeLaborer(index) {
      this.laborerList.splice(index, 1);
    },

    removeAllLaborers() {
      this.laborerList = [];
    },

    resetAllFormData() {
      this.resetAddLaborerInputs();
      this.resetDimensionInputs();
      this.removeAllLaborers();
    },

    calculateTimeToDigHole() {
      // Convert dimensions based on selected unit
      const { width, length, depth, unit } = this.diggingArea;
      let convertedWidth, convertedLength, convertedDepth;

      switch (unit) {
        case 'Yards³':
          convertedWidth = width * 3;
          convertedLength = length * 3;
          convertedDepth = depth * 3;
          break;
        case 'Meters³':
          convertedWidth = width * 3.28084;
          convertedLength = length * 3.28084;
          convertedDepth = depth * 3.28084;
          break;
        case 'Miles³':
          convertedWidth = width * 5280 * 3;
          convertedLength = length * 5280 * 3;
          convertedDepth = depth * 5280 * 3;
          break;
        case 'Kilometers³':
          convertedWidth = width * 3280.84;
          convertedLength = length * 3280.84;
          convertedDepth = depth * 3280.84;
          break;
        default: // Default is 'Feet³'
          convertedWidth = width;
          convertedLength = length;
          convertedDepth = depth;
      }

      const diggingArea = new DiggingRate.Cube(convertedWidth, convertedLength, convertedDepth);

      let totalDiggingRate = 0;

      // Calculate total digging rate
      this.laborerList.forEach(digger => {
        let digRate = digger.digRate;
        switch (digger.digRateUnit) {
          case '10 Seconds':
            digRate *= 6;
            break;
          case '30 Seconds':
            digRate *= 2;
            break;
          case '10 Minutes':
            digRate /= 10;
            break;
          case '30 Minutes':
            digRate /= 30;
            break;
        }
        totalDiggingRate += digRate;
      });

      // Calculate total work time
      const totalWorkTime = diggingArea.GetVolume() / totalDiggingRate;

      // Convert total work time to appropriate unit
      let totalTimeString;
      if (totalWorkTime >= 1440) { // If total work time is 1 day or more
        totalTimeString = `${(totalWorkTime / 1440).toFixed(2)} days`;
      } else if (totalWorkTime >= 60) { // If total work time is 1 hour or more
        totalTimeString = `${(totalWorkTime / 60).toFixed(2)} hours`;
      } else {
        totalTimeString = `${totalWorkTime.toFixed(2)} minutes`;
      }

      this.totalDiggingTime = totalTimeString;
    }
  }
}
</script>

<style scoped>
.icon-big .v-icon { 
  font-size: 32px; /* Example size, adjust as needed */
}

.icon-border .v-icon { 
  border: 2px solid #000; /* Example border style, adjust as needed */
}
</style>