<template>
  <v-container>
    <v-row>
      <!-- Dashboard Header -->
      <v-col cols="12" class="mb-3 d-flex justify-center">
        <v-sheet>
          <v-row>
            <h1 class="ml-4 mt-6 mb-2 text-h4 font-weight-semi-bold">
              Ethereum Playground
            </h1>
          </v-row>
        </v-sheet>
      </v-col>

      <v-col
        cols="12"
      >
        <!-- NFTS -->
        <v-row>
          <v-col>
            <v-card flat>
              <v-card-title class="d-flex align-center pe-2">
                <v-icon icon="mdi-image-auto-adjust "></v-icon> &nbsp;
                 NFT Collection

                <v-spacer></v-spacer>
              </v-card-title>

              <v-sheet 
                class="pa-3"
                rounded
              >          

                <v-row  justify="center">
                  <v-col cols="auto">
                    <nft-card :nft="nftCollection.nft1"></nft-card>
                  </v-col>  

                  <v-col cols="auto">
                    <nft-card :nft="nftCollection.nft2"></nft-card>
                  </v-col>  

                  <v-col cols="auto">
                    <nft-card :nft="nftCollection.nft3"></nft-card>
                  </v-col>  

                </v-row>  
            
              </v-sheet>
      
            </v-card>
          </v-col>
        </v-row>  

        <!-- Smart Contracts -->
        <v-row>
          <v-col>
            <v-card flat>
              <v-card-title class="d-flex align-center pe-2">
                <v-icon icon="mdi-file-document-edit-outline "></v-icon> &nbsp;
                 Smart Contracts

                <v-spacer></v-spacer>
              </v-card-title>

              <v-sheet 
                class="pa-3"
                rounded
              >          
                <BannerItem v-for="(banner, index) in banners" :key="index" :color="banner.color" :lines="banner.lines" :icon="banner.icon" :text="banner.text" :actions="banner.actions" />
              </v-sheet>
      
            </v-card>
          </v-col>
        </v-row>  

        <!-- Donate -->
        <v-row>
          <v-col>
            <v-card flat>
              <v-card-title class="d-flex align-center pe-2">
                <v-icon icon="mdi-ethereum"></v-icon> &nbsp;
                 Send Me Ethereum

                <v-spacer></v-spacer>
              </v-card-title>

              <v-sheet 
                class="pa-3 ml-2"
                rounded
              >        
              <v-btn
                color="cyan"
                prepend-icon="mdi-currency-eth "
                text="Donate Ethereum"
                variant="outlined"
                v-bind="activatorProps"
              ></v-btn>

              <v-btn class="mx-2"
                color="cyan"
                prepend-icon="mdi-credit-card"
                text="Donate Paypal"
                variant="outlined"
                v-bind="activatorProps"
              ></v-btn>

              <v-btn
                color="cyan"
                prepend-icon="mdi-cash"
                text="Donate Venmo"
                variant="outlined"
                v-bind="activatorProps"
              ></v-btn>
              </v-sheet>
      
            </v-card>
          </v-col>
        </v-row>  

      </v-col>
    </v-row>
  </v-container>  
</template>

<script>
import Web3 from 'web3';
import NftCard from './NftCardComponent.vue';
import BannerItem from "./BannerComponent.vue";

// Infura endpoint
const infuraEndpoint = '';

export default {
  name: 'DigDonationsView',

  components: {
    NftCard,
    BannerItem
  },

  data() {
    return {
      nftCollection: {
        nft1: {
          name: 'Digging Shovel',
          image: 'https://example.com/nft-image.jpg',
          collection: 'Excavation Land',
          description: 'A rare digital landscape of dirt, words words blah.',
          stats: [
            { label: 'Price', value: '0.0001 ETH', icon: 'mdi-currency-eth' },
            { label: 'Owner', value: '0x123...789', icon: 'mdi-account' },
          ],
        },
        nft2: {
          name: 'Semetary Hole',
          image: 'https://example.com/nft-image.jpg',
          collection: 'Excavation Land',
          description: 'A rare digital landscape of dirt, words words blah.',
          stats: [
            { label: 'Price', value: '0.0005 ETH', icon: 'mdi-currency-eth' },
            { label: 'Owner', value: '0x123...789', icon: 'mdi-account' },
          ],
        },
        nft3: {
          name: 'Dead Dirt',
          image: 'https://example.com/nft-image.jpg',
          collection: 'Excavation Land',
          description: 'A rare digital landscape of dirt, words words blah.',
          stats: [
            { label: 'Price', value: '0.0010 ETH', icon: 'mdi-currency-eth' },
            { label: 'Owner', value: '0x123...789', icon: 'mdi-account' },
          ],
        },
      },

      banners: [
        {
          color: "deep-purple-accent-4",
          lines: 2,
          icon: "mdi-account-filter",
          text: "Banner with two lines of text. If the text is too long to fit on two lines then an ellipsis will be used to hide the remaining content. So this next line will be hidden.",
          actions: ["Action", "Action"]
        },
        {
          color: "indigo-accent-4",
          lines: 2,
          icon: "mdi-account-filter",
          text: "Banner with two lines of text. If the text is too long to fit on two lines then an ellipsis will be used to hide the remaining content. So this next line will be hidden.",
          actions: ["Action", "Action"]
        },
        {
          color: "cyan-darken-4",
          lines: 2,
          icon: "mdi-account-filter",
          text: "Banner with two lines of text. If the text is too long to fit on two lines then an ellipsis will be used to hide the remaining content. So this next line will be hidden.",
          actions: ["Action", "Action"]
        },
        // Add more banner objects here as needed
      ],

    };
  },
  computed: {
    subtotal () {
      return this.products.reduce((acc, product) => acc + product.quantity * product.price, 0)
    },
    total () {
      return this.subtotal + Number(this.shipping ?? 0)
    },
  },

  async created() {
    try {
      this.web3 = new Web3(new Web3.providers.HttpProvider(infuraEndpoint));
      
      this.contract = new this.web3.eth.Contract('YourContractABI', 'YourContractAddress');
    } catch (error) {
      console.error('Failed to connect to Infura:', error);
    }
  },

  methods: {
    async makeDonation() {
      try {
        const accounts = await this.web3.eth.getAccounts();
        const donationWei = this.web3.utils.toWei(this.donationAmount, 'ether');
        const tx = await this.contract.methods.donate().send({ from: accounts[0], value: donationWei });
        console.log('Transaction Hash:', tx.transactionHash);
      } catch (error) {
        console.error('Failed to make donation:', error);
      }
    }
  }
}
</script>

<style>
/* Add your styles here */
</style>