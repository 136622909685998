export class DiggingRate {
    static PersonDigger = class {
      constructor(name, cubicSoilMovementPerMinute) {
        this.Name = name;
        this.CubicSoilMovementPerMinute = cubicSoilMovementPerMinute;
      }
    };
  
    static Cube = class {
      constructor(width, length, depth) {
        this.Width = width;
        this.Length = length;
        this.Depth = depth;
      }
  
      GetVolume() {
        return this.Width * this.Length * this.Depth;
      }
    };
  
    GetDiggingHours(diggingArea, soilMovedPerMinute) {
      const totalMinutes = diggingArea.GetVolume() / soilMovedPerMinute;
      const totalHours = totalMinutes / 60;
  
      return totalHours;
    }
  }