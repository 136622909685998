<template>
  <v-container>
    <v-row>
      <!-- Dashboard Header -->
      <v-col cols="12" class="mb-3 d-flex justify-center">
        <v-sheet>
          <v-row align="center">
            <h1 class="ml-4 mt-6 mb-2 text-h4 font-weight-semi-bold">
              Digger Dashboard
            </h1>
          </v-row>
        </v-sheet>
      </v-col>

      <!-- Chart 1: Larger (Takes more space) -->
      <v-col cols="12" md="9">
        <v-sheet elevation="3" rounded class="pa-8" height="580">
          <chart-component :chart-data="chartData1" :chart-options="lineChartOptions" chart-type="line"></chart-component>
        </v-sheet>

      </v-col>

      <!-- Chart 2: Smaller -->
      <v-col cols="12" md="3">
        <v-sheet elevation="3" rounded class="pa-8" height="580"> 
          <chart-component :chart-data="chartData4" :chart-options="PieChartOptions" chart-type="pie"></chart-component>
        </v-sheet>
      </v-col>

      <!-- Chart 3: same size -->
      <v-col cols="6" md="6">
        <v-sheet elevation="3" rounded class="pa-12" height="520">
          <chart-component :chart-data="chartData3" :chart-options="BubbleChartOptions" chart-type="bubble"></chart-component>
        </v-sheet>
      </v-col>

      <!-- Chart 4: same size -->
      <v-col cols="6" md="6">
        <v-sheet elevation="3" rounded class="pa-8" height="520">
          <chart-component :chart-data="chartData2" :chart-options="BarChartOptions" chart-type="bar"></chart-component>
        </v-sheet>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import ChartComponent from './ChartComponent.vue';

export default {
  components: {
    ChartComponent,
  },
  data() {
    return {
      // Chart 1: Line Chart Data
      chartData1: {
        labels: ['January', 'February', 'March', 'April', 'May'],
        datasets: [
          {
            label: 'Sales',
            borderColor: '#42A5F5',
            animation: {
              delay: (context) => {
                return context.dataIndex * 50;
              },
            },
            fill: false,
            data: [65, 59, 80, 81, 56],
          },
          {
            label: 'Expenses',
            borderColor: '#FFA726',
            animation: {
              delay: (context) => {
                return context.dataIndex * 100;
              },
            },
            fill: false,
            data: [28, 48, 40, 19, 86],
          }
        ],
      },

      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Cubic Feet Dug by Workers Over Time',
            font: {
              size: 20
            },
            position: 'top',
          },
        },
        
      },

      // Chart 2: Pie Chart Data
      chartData4: {
        labels: ['Worker 1', 'Worker 2', 'Worker 3'],
        datasets: [
          {
            data: [300, 50, 100],
            animation: {
              delay: (context) => {
                return context.dataIndex * 200;
              },
            },
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          }
        ],
      },

      PieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Labor Contribution Total',
            font: {
              size: 20
            },
            position: 'top',
          },
        },
        
      },

      // Chart 3: Bubble Chart Data
      chartData3: {
        datasets: [
          {
            label: 'First Dataset',
            data: [
              { x: 20, y: 30, r: 15 },
              { x: 40, y: 10, r: 10 },
              { x: 10, y: 20, r: 20 },
              { x: 80, y: 80, r: 30 },
            ],
            animation: {
              delay: (context) => {
                return context.dataIndex * 600;
              },
            },
            backgroundColor: '#FF6384',
          },
        ],
      },

      BubbleChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Area Excavated By Worker',
            font: {
              size: 20
            },
            position: 'top',
          },
        },
        
      },

      // Chart 4: Bar Chart Data
      chartData2: {
        labels: ['Q1', 'Q2', 'Q3', 'Q4'],
        datasets: [
          {
            label: 'Profit',
            backgroundColor: '#42A5F5',
            animation: {
              delay: (context) => {
                return context.dataIndex * 400;
              },
            },
            data: [20, 30, 50, 70],
          },
          {
            label: 'Loss',
            backgroundColor: '#FFA726',
            animation: {
              delay: (context) => {
                return context.dataIndex * 500;
              },
            },
            data: [5, 15, 10, 30],
          }
        ],
      },

      BarChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Worker Digging Speed',
            font: {
              size: 20
            },
            position: 'top',
          },
        },
        
      },

    };
  },
}
</script>

